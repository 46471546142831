<template >
  <h1 class="mt-10 mx-2 mx-sm-16">Home</h1>
</template>

<script>



export default {
  name: "Home",
}
</script>

<style>
</style>
